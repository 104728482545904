@import (less) "mixins.less";

/*************FORMS************/
@content: #666;
@labelColor: #666;
@inputColor: #bfbfbf;
@inputBG: #fff;
@inputBorder: @content;
@inputFocusBorder: #666;
@inputFocusBG: #fff;
@errorBG: #bfbfbf;
@errorBorder: #f10e0e;
@errorLabel: #f10e0e;

.gform_wrapper{
            .bodyfont()!important;
            width:100%!important;
            margin-top:0!important;
            h2.gsection_title{
            	margin-top:15px!important;
            	font-weight:400!important;
            	font-size:24px!important;
            	border-bottom:1px solid @grayLight;
            	padding-bottom:15px!important;
            	margin-bottom:15px!important;
            	}
            
            label{
                .montserrat(18px)!important;
                .uppercase();
                font-size:15px;
                display:block;
                color:@aquaMed;
                font-weight:400!important;
                }
            .ginput_complex label{
                font-size:12px!important;
                }
            input[type=text], textarea{
                background:#fff;
                border:1px solid @grayLight;
                padding:12px!important;
                color:@content;
                font-size:16px;
                .transition();
                outline:none;
                ::-webkit-input-placeholder {
                       color: #fff;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                       color: #fff;  
                    }
                    
                    ::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff;  
                    }
                    
                    :-ms-input-placeholder {  
                       color: #fff;  
                    }
                }
            input[type=text]:focus, textarea:focus{
                background:#f1f1f1;
                }
            
            select{
                width:100%!important;
                }
            ul.gform_fields{
                padding:0px 0 10px 0!important;
                margin:0!important;
                li{
                    padding-left:0!important;
                    padding-bottom:3px!important;
                    background:none!important;
                    margin-bottom:0!important;
                	&.hide-label{
                		label.gfield_label{display:none!important;}
                		}
                    i.fa-circle{
                    	display:none!important;
                    	}
                    }
                li.gfield_error{
                    border:1px solid #ccc;
                    background:@grayLight!important;
                    padding:10px!important;
                    label, .gfield_description{
                        color:@aquaMed!important;
                        }
                    }
                }
            ul.gfield_radio{
            	padding:0!important;
            	li{ 
            	label{
            		.bitter(18px)!important;
            		.italic();
            		text-transform:none;
            		.transition();
            		}
            	input[type=radio]:checked+label{
            		padding-left:12px;
            		color:@yellow;
            		.bold()!important;
            		}
            	}
            }
            ul.gfield_checkbox{
            	padding:0!important;
            	li{ 
            	input[type=checkbox]{
            		vertical-align: top;
            		}
            	label{
            		.bitter(18px)!important;
            		.italic();
            		text-transform:none;
            		.transition();
            		vertical-align:top;
            		padding-left:5px;
            		}
            	input[type=checkbox]:checked+label{
            		padding-left:12px;
            		.bold()!important;
            		}
            	}
            }
            span.ginput_total{
            	.montserrat(20px)!important;
            	color:@aquaMed!important;
            	}
            }
div.validation_error{
		color:@aquaMed!important;
		.bitter(18px)!important;
		.italic();
		font-weight:normal;
		border-top:1px solid @aqua!important;
		border-bottom:1px solid @aqua!important;
		}
.gform_footer{
    margin-top:0!important;
    padding-top:0!important;
    button{
    	font-size:24px!important;
    	}
    }
#gforms_confirmation_message{
    .bodyfont();
    padding:20px;
    border:5px solid #fff;
    text-align:center;
    }
    
.gform_wrapper.two-column_wrapper {
	max-width: 100%;
}

.gform_wrapper.two-column_wrapper ul.gform_fields,
.gform_wrapper.two-column_wrapper ul.gform_column li.gsection:first-child {
	display: none;
}

.gform_wrapper.two-column_wrapper ul.gform_fields.gform_column {
	display: block;
	float:left;
}

.gform_wrapper.two-column_wrapper ul.one-half {
	margin-left: 6% !important;
	width: 47%;
}
.gform_wrapper.two-column_wrapper ul.one-half.first {
	margin-left: 0 !important;
}


.gform_wrapper .gsection {
	border-bottom: none !important;
}

.gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
	padding: 0 !important;
}

@media only screen and (max-width: 1075px) {
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		width: 100%;
	}
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		margin-left: 0 !important;
	}
	
	.gform_wrapper .gsection {
		display: none !important; /* remove !important if it's not needed */
	}
	
	#gform_wrapper_1 li {
		margin-bottom: 10px;
	}
	
}

@media only screen and (min-width: 641px)
{
#main-header, #main-content, #footer{
	li.gfield.gf_left_half, li.gfield.gf_right_half{
            width:49%!important;
            	}
    li.gfield.gf_left_half{
    	margin-right:2%!important;
    	}
	.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error{
	max-width:100%!important;
	label{
		color:@aquaMed!important;
		}
	input{
		border:1px solid @aquaMed!important;
		color:@aquaMed!important;
		}
	.gfield_description,.validation_message{
		color:@aquaMed!important;
		.montserrat(10px);
		.uppercase();
		font-weight:normal!important;
		padding-top:3px!important;
		}
	
	}
}
#footer{
	.validation_error{display: none!important;}
	.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
		background:transparent!important;
		padding:0!important;
		border:none!important;
		}
		.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
	max-width:100%!important;
	label{
		color:@yellow!important;
		}
	input{
		border:1px solid @yellow!important;
		color:@yellow!important;
		}
	.gfield_description,.validation_message{
		color:@yellow!important;
		.montserrat(10px);
		.uppercase();
		font-weight:normal!important;
		padding-top:3px!important;
		}
	button.submit{color:@yellow!important;}
	}
	}
}